<template>
  <b-modal
    v-model="modalIsShown"
    :hide-footer="true"
    :centered="true"
    title="Administrator Info"
    size="lg"
  >
    <slot name="body">
      <div v-if="!isUserSignedIn">
        <p>
          An anonymous user is viewing procedure <strong>{{ pageData.procedure.name }}</strong> for location <strong>{{ pageData.location.city }}, {{ pageData.location.stateAbbreviation }} ({{ pageData.location.zipCodeId }})</strong>.
        </p>
        <p>The procedure is linked to the category <strong>{{ pageData.procedure.category.name }}</strong>.</p>
      </div>

      <div v-if="isUserSignedIn">
        <p>User <strong>{{ signedInUser.firstName }} {{ signedInUser.lastName }} ({{ signedInUser.email }})</strong>, who is linked to subscriber <strong>{{ signedInUser.subscriber.name }} </strong> is viewing procedure <strong>{{ pageData.procedure.name }}</strong> for location <strong>{{ pageData.location.city }}, {{ pageData.location.stateAbbreviation }} ({{ pageData.location.zipCodeId }})</strong>.</p>

        <div v-if="signedInUserSubscriberContent">
          <h5>Subscriber specific content:</h5>

          <p>The following subscriber specific content is being show to the user:</p>

          <table class="table table-striped">
            <thead>
              <th>Name</th>
              <th>Value</th>
            </thead>
            <tbody>
              <tr
                v-for="(contentItem, key) in removeUnneededSubscriberContentProperties(signedInUserSubscriberContent.localization.localizations[0])"
                :key="key"
              >
                <td>{{ key }}</td>
                <td>
                  <div v-if="typeof contentItem === 'object'">
                    <vue-markdown :source="contentItem.markdown" />
                  </div>
                  <div v-if="typeof contentItem !== 'object'">
                    {{ contentItem }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="!signedInUserSubscriberContent">
          <p>The user is not being shown subscriber specific content.</p>
        </div>

        <h5>Subscriber category settings:</h5>
        <p>The procedure is linked to category <strong>{{ pageData.procedure.category.name }}</strong>. This category <strong><span v-if="pageData.subscriberCategory">does</span><span v-if="!pageData.subscriberCategory">does not</span></strong> have subscriber category settings.</p>

        <div v-if="pageData.subscriberCategory">
          <p>The subscriber category settings come from <strong><span v-if="subscriberCategoryIsProcedureCategory">it's linked category ({{ pageData.procedure.category.name }})</span><span v-if="!subscriberCategoryIsProcedureCategory">a parent category ({{ pageData.subscriberCategory.category.name }})</span></strong>.</p>

          <table class="table table-striped">
            <thead>
              <th>Setting Name</th>
              <th>Setting</th>
            </thead>
            <tbody>
              <tr
                v-for="(value, key) in removeUnneededSubscriberCategoryProperties(pageData.subscriberCategory)"
                :key="key"
              >
                <td>{{ key }}</td>
                <td>{{ value }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h5>Facility exclusions:</h5>

        <div v-if="facilityExclusions !== undefined">
          <p>The subscriber <strong><span v-if="hasFacilityExclusions">does</span><span v-if="!hasFacilityExclusions">does not</span></strong> have facility exclusions set. <span v-if="hasFacilityExclusions">These facilities will not appear in "Preferred Benefit Provider" or "Other Medical Providers" listings for this procedure.</span></p>

          <table
            v-if="hasFacilityExclusions"
            class="table table-striped"
          >
            <thead>
              <th>Facility Name</th>
            </thead>
            <tbody>
              <tr
                v-for="facilityExclusion in facilityExclusions"
                :key="facilityExclusion.facilityNpi"
              >
                <td>{{ facilityExclusion.facility.name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="facilityExclusions === undefined"
          class="text-center"
        >
          <font-awesome-icon
            icon="spinner"
            spin
            class="page-loading__icon"
          />
        </div>
      </div>
    </slot>
  </b-modal>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import { cloneDeep } from 'lodash'
import { getSubscriberFacilityExclusions } from '../../../lib/api'

export default {
  name: 'AdministratorInfoModal',
  components: {
    VueMarkdown
  },
  data () {
    return {
      modalIsShown: false,
      facilityExclusions: undefined
    }
  },
  computed: {
    signedInUser () {
      return this.$store.state.app.signedInUser
    },
    pageData () {
      return this.$store.state.procedureDetail.pageData
    },
    isUserSignedIn () {
      return this.signedInUser !== null
    },
    subscriberCategoryIsProcedureCategory () {
      return this.pageData.procedure.category.categoryId === this.pageData.subscriberCategory.categoryId
    },
    signedInUserSubscriberContent () {
      return this.$store.state.app.signedInUserSubscriberContent
    },
    hasFacilityExclusions () {
      return this.facilityExclusions !== undefined && this.facilityExclusions !== null && this.facilityExclusions.length > 0
    }
  },
  async mounted () {
    if (this.signedInUser && this.signedInUser.subscriberId) {
      const subscriberFacilityExclusionsResponse = await getSubscriberFacilityExclusions(this.signedInUser.subscriberId)
      this.facilityExclusions = subscriberFacilityExclusionsResponse.data
    }
  },
  methods: {
    showModal () {
      this.modalIsShown = true
    },
    hideModal () {
      this.modalIsShown = false
    },
    removeUnneededSubscriberContentProperties (contentObject) {
      const objectCopy = cloneDeep(contentObject)

      delete objectCopy.contentItemId
      delete objectCopy.contentItemVersionId
      delete objectCopy.localization

      return objectCopy
    },
    removeUnneededSubscriberCategoryProperties (subscriberContentObject) {
      const objectCopy = cloneDeep(subscriberContentObject)

      delete objectCopy.subscriberId
      delete objectCopy.categoryId
      delete objectCopy.subscriber
      delete objectCopy.category

      return objectCopy
    }
  }
}
</script>

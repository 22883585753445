<template>
  <form class="form">
    <div class="form-group">
      <b-form-input
        v-model.trim="$v.scheduleAppointmentRequest.firstName.$model"
        type="text"
        class="form-control schedule-appointment-form__form-control"
        placeholder="First Name"
        aria-label="First Name"
        :state="validateState('firstName')"
      />
      <b-form-invalid-feedback id="input-appointment-first-name">
        This field is required.
      </b-form-invalid-feedback>
    </div>
    <div class="form-group">
      <b-form-input
        v-model.trim="$v.scheduleAppointmentRequest.lastName.$model"
        type="text"
        class="form-control schedule-appointment-form__form-control"
        placeholder="Last Name"
        aria-label="Last Name"
        :state="validateState('lastName')"
      />
      <b-form-invalid-feedback id="input-appointment-last-name">
        This field is required.
      </b-form-invalid-feedback>
    </div>
    <div class="form-group">
      <b-form-input
        v-model.trim="$v.scheduleAppointmentRequest.emailAddress.$model"
        type="email"
        class="form-control schedule-appointment-form__form-control"
        placeholder="Email"
        aria-label="Email"
        :state="validateState('emailAddress')"
      />
      <b-form-invalid-feedback id="input-appointment-email-address">
        This field requires a valid email address.
      </b-form-invalid-feedback>
    </div>
    <div class="form-group">
      <b-form-input
        v-model.trim="$v.scheduleAppointmentRequest.phoneNumber.$model"
        type="tel"
        class="form-control schedule-appointment-form__form-control"
        placeholder="Phone Number - ex: ###-###-###"
        aria-label="Phone Number - ex: ###-###-###"
        :state="validateState('phoneNumber')"
        :formatter="telFormatter"
      />
      <b-form-invalid-feedback id="input-appointment-phone-number">
        A valid phone number with format ###-###-#### is required.
      </b-form-invalid-feedback>
    </div>
    <div class="form-group text-center">
      <strong>Procedure:</strong> {{ procedure.name }}
    </div>
    <div class="form-group text-center">
      <button
        type="button"
        class="btn btn-primary btn-lg px-4 font-weight-bold"
        @click="handleScheduleAppointmentBtnClick"
      >
        Submit
      </button>
    </div>
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { email, required, helpers } from 'vuelidate/lib/validators'
import { postScheduleAppointmentRequest } from '../../../lib/api'
import { mutationTypes as appMutationTypes } from '../../../store/modules/app'

const tel = helpers.regex('tel', /^\d{3}-\d{3}-\d{4}$/)

export default {
  name: 'ScheduleAppointmentForm',
  mixins: [
    validationMixin
  ],
  props: {
    procedure: { type: Object, defualt: null, required: true },
    procedureBundle: { type: Object, defualt: null, required: true }
  },
  data () {
    return {
      scheduleAppointmentRequest: {
        firstName: null,
        lastName: null,
        emailAddress: null,
        phoneNumber: null,
        procedureName: this.procedure.name,
        procedureBundleId: this.procedureBundle.bundleId,
        zipCode: this.$store.state.app.selectedLocation.zipCodeId
      }
    }
  },
  methods: {
    async handleScheduleAppointmentBtnClick () {
      this.$v.$touch()

      if (this.$v.$invalid === false) {
        try {
          await postScheduleAppointmentRequest(this.scheduleAppointmentRequest)
          const alertMsg = 'Your schedule appointment request has been sent successfully!'
          this.$store.commit(appMutationTypes.APP__SET_ALERT, { msg: alertMsg, variant: 'success' })
          this.$emit('appointment-requested-submitted')
        } catch (err) {
          const alertMsg = 'There was an issue sending your schedule appointment request. Please try again. If the issue persists please contact customer support.'
          this.$store.commit(appMutationTypes.APP__SET_ALERT, { msg: alertMsg, variant: 'danger' })
        }
      }
    },
    validateState (name) {
      const { $dirty, $error } = this.$v.scheduleAppointmentRequest[name]
      return $dirty ? !$error : null
    },
    telFormatter (v, ev) {
      if (ev.inputType === undefined || ev.inputType === 'deleteContentBackward') {
        return v
      }

      const numbersOnly = v.replace(/\D/g, '')

      if (numbersOnly.match(/^\d{3}$/)) {
        return `${numbersOnly}-`
      }

      if (numbersOnly.match(/^(\d{3})(\d{3})(\d{0,4})/)) {
        return numbersOnly.replace(/(\d{3})(\d{3})(\d{0,4})/, '$1-$2-$3')
      }

      return v
    }
  },
  validations: {
    scheduleAppointmentRequest: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      emailAddress: {
        email,
        required
      },
      phoneNumber: {
        required,
        tel
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.schedule-appointment-form {
  &__form-control {
    border-color: $primary;
    border-radius: 0;
    padding: 24px 12px;
  }
}
</style>

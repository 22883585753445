<template>
  <div class="procedure-price-detail mb-4 px-3">
    <div class="procedure-price-detail__graphic pb-2">
      <img
        :src="priceRangeGraphic"
        class="img-fluid"
      >
      <h4 class="procedure-price-detail__head d-none d-lg-block">
        {{ getSubscriberContentItem('fairPriceTitle') }}
      </h4>
      <div
        v-if="!showJustFairPrice"
        class="procedure-price-detail__price procedure-price-detail__price--min h6 m-0"
      >
        <font-awesome-icon icon="star" /> {{ lowPriceFormatted }}
      </div>
      <div
        v-if="!showJustFairPrice"
        class="procedure-price-detail__price procedure-price-detail__price--max h6 m-0"
      >
        <font-awesome-icon icon="exclamation-circle" /> {{ highPriceFormatted }}
      </div>
      <div
        v-if="pageData.procedurePricing.fairPrice"
        class="procedure-price-detail__price procedure-price-detail__price--fair h4 m-0"
      >
        <font-awesome-icon
          icon="thumbs-up"
          class="procedure-price-detail__price-icon"
        />
        {{ fairPriceFormatted }}
      </div>
    </div>
  </div>
</template>

<script>
import priceDetailBarGRaphic from '../../../assets/img/procedure-price-detail-bar.png'
import subscriberContentMixin from '../../../lib/mixins/subscriberContent'

export default {
  name: 'ProcedurePriceDetail',
  mixins: [
    subscriberContentMixin
  ],
  computed: {
    pageData () {
      return this.$store.state.procedureDetail.pageData
    },
    priceRangeGraphic () {
      return priceDetailBarGRaphic
    },
    showJustFairPrice () {
      return this.pageData.procedurePricing.fairPrice &&
        (
          (
            !this.pageData.procedurePricing.lowPrice &&
            !this.pageData.procedurePricing.highPrice
          ) ||
          (
            this.pageData.procedurePricing.lowPrice === this.pageData.procedurePricing.highPrice
          )
        )
    },
    showJustLowHighPrice () {
      return !this.pageData.procedurePricing.fairPrice &&
        this.pageData.procedurePricing.lowPrice &&
        this.pageData.procedurePricing.highPrice
    },
    showAllPrices () {
      return this.pageData.procedurePricing.fairPrice !== null &&
        this.pageData.procedurePricing.lowPrice !== this.pageData.procedurePricing.highPrice
    },
    lowPriceFormatted () {
      return this.formatCurrency(this.pageData.procedurePricing.lowPrice)
    },
    fairPriceFormatted () {
      return this.formatCurrency(this.pageData.procedurePricing.fairPrice)
    },
    highPriceFormatted () {
      return this.formatCurrency(this.pageData.procedurePricing.highPrice)
    }
  },
  methods: {
    formatCurrency (value) {
      return `$${new Intl.NumberFormat('en-US').format(parseInt(value, 10))}`
    }
  }
}
</script>

<style lang="scss" scoped>
.procedure-price-detail {
  color: $white;
  height: 74px;

  &__graphic {
    position: relative;
    top: 28px;
  }

  &__head {
    font-weight: normal;
    font-size: 0.8rem;
    position: absolute;
    top: -20px;
    left: 0;
  }

  &__price {
    font-size: 0.8rem;

    &--fair {
      font-size: 0.8rem;
      position: absolute;
      top: -18px;
      left: 42%;
    }

    &--min {
      position: absolute;
      bottom: -12px;
      left: 0;
    }

    &--max {
      position: absolute;
      bottom: -12px;
      right: 0;
      text-align: right;
    }

    &-label {
      font-weight: bold;
      text-transform: uppercase;

      &--low {
        color: #4E9B80;
      }

      &--high {
        color: #C97474;
      }
    }

    &-icon {
      font-size: 0.8rem;
    }
  }

  @include media-breakpoint-up(lg) {
    height: 84px;

    &__graphic {
      top: 32px;
    }

    &__head {
      font-size: 1rem;
    }

    &__price {
      &--fair {
        font-size: 1.2rem;
        top: -22px;
        left: 37%;
      }
    }
  }
}
</style>

<template>
  <b-modal
    v-model="modalIsShown"
    :hide-footer="true"
    :centered="true"
    :title="procedureDetailsPhoneNumberModalTitle"
    content-class="rounded-0"
  >
    <slot name="body">
      <vue-markdown
        v-if="subscriberContent.decisionSupportAvailableCopy"
        :source="subscriberContent.decisionSupportAvailableCopy.markdown"
      />
      <oc-content
        v-if="!subscriberContent.decisionSupportAvailableCopy"
        :item="$oc.content.procedureDetailsPhoneNumberModalBody"
      />
      <h3
        v-if="isLgUp"
        class="text-center"
      >
        {{ phoneNumber }}
      </h3>
      <div class="row">
        <div class="col-12 col-md-8 offset-md-2">
          <a
            v-if="isMdDown"
            class="btn btn-primary btn-block mobile-cta"
            :href="mobileCtaHref"
          >
            {{ phoneNumber }}
          </a>
        </div>
      </div>
    </slot>
  </b-modal>
</template>

<script>
import { CommonBands } from 'vue-media-queries'
import subscriberContentMixin from '../../../lib/mixins/subscriberContent'
import VueMarkdown from 'vue-markdown'

export default {
  name: 'ContactBenefitBuddyModal',
  components: { VueMarkdown },
  mixins: [CommonBands.Bootstrap4.mixin, subscriberContentMixin],
  props: {
    phoneNumber: { type: String, default: null, required: true }
  },
  data () {
    return {
      modalIsShown: false
    }
  },
  computed: {
    subscriberContent () {
      return {
        decisionSupportAvailableTitle: this.getSubscriberContentItem('decisionSupportAvailableTitle'),
        decisionSupportAvailableCopy: this.getSubscriberContentItem('decisionSupportAvailableCopy')
      }
    },
    procedureDetailsPhoneNumberModalTitle () {
      if (this.subscriberContent.decisionSupportAvailableTitle) {
        return this.subscriberContent.decisionSupportAvailableTitle
      } else {
        return this.$oc.getSelectedLocalization(this.$oc.content.procedureDetailsPhoneNumberModalTitle.localization).content
      }
    },
    mobileCtaHref () {
      return `tel://${this.phoneNumber}`
    }
  },
  methods: {
    showModal () {
      this.modalIsShown = true
    },
    hideModal () {
      this.modalIsShown = false
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-cta {
  border-radius: 17px;
  text-decoration: none;
}
</style>

<template>
  <div class="text-center mt-4">
    <button
      type="button"
      class="btn btn-warning"
      @click="handleBtnClick"
    >
      <font-awesome-icon icon="info-circle" />
      Administrator Info
    </button>
    <administrator-info-modal ref="administratorInfoModal" />
  </div>
</template>

<script>
import AdministratorInfoModal from './AdministratorInfoModal'

export default {
  name: 'AdministratorInfo',
  components: { AdministratorInfoModal },
  methods: {
    handleBtnClick () {
      this.$refs.administratorInfoModal.showModal()
    }
  }
}
</script>

<style>

</style>

<template>
  <div class="facility-details">
    <div
      v-if="showName"
      class="row"
    >
      <div class="col">
        <h4 class="facility-details__head mb-1">
          {{ facility.name }}
        </h4>
      </div>
    </div>
    <div class="row d-none d-md-block">
      <div class="col">
        <div>
          {{ facility.address1 }}
          {{ facility.address2 }}<br>
          {{ facility.city }},
          {{ facility.stateAbbreviation }}
          {{ facility.zipCodeId }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FacilityDetails',
  props: {
    facility: { type: Object, default: null, required: true },
    showName: { type: Boolean, default: true, required: false }
  }
}
</script>

<style scoped lang="scss">
.facility-details {
  font-size: 0.8rem;

  &__head {
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }

  @include media-breakpoint-up(md) {
    &__head {
      font-size: 1rem;
    }
  }
}
</style>

<template>
  <div class="row mb-2 location-sorting-filters">
    <div class="col-auto">
      <sort-options />
    </div>
    <div class="col-auto">
      <distance-filters />
    </div>
    <div
      v-if="showProviderTypeFilters"
      class="col-auto"
    >
      <provider-type-filters />
    </div>
    <div
      v-if="false"
      class="col-auto"
    >
      <facility-type-filter />
    </div>
  </div>
</template>

<script>
import DistanceFilters from './Controls/DistanceFilters'
import FacilityTypeFilter from './Controls/FacilityTypeFilter.vue'
import ProviderTypeFilters from './Controls/ProviderTypeFilters'
import SortOptions from './Controls/SortOptions'
import { getterTypes } from '../../../store/modules/procedure-detail'

export default {
  name: 'LocationListingDisplayControls',
  components: {
    DistanceFilters,
    FacilityTypeFilter,
    ProviderTypeFilters,
    SortOptions
  },
  computed: {
    showProviderTypeFilters () {
      return this.$store.getters[getterTypes.PROCEDURE_DETAIL__GET_SHOW_PROVIDER_TYPE_FILTERS]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

export default {
  computed: {
    defaultSubscriberContent () {
      if (this.$store.state.app.defaultSubscriberContent !== null) {
        return this.$oc.getSelectedLocalization(this.$store.state.app.defaultSubscriberContent.localization)
      }

      return null
    },
    signedInUserSubscriberContent () {
      if (this.$store.state.app.signedInUserSubscriberContent !== null) {
        return this.$oc.getSelectedLocalization(this.$store.state.app.signedInUserSubscriberContent.localization)
      }

      return null
    }
  },
  methods: {
    getSubscriberContentItem (contentItemId) {
      if (this.signedInUserSubscriberContent !== null && Object.keys(this.signedInUserSubscriberContent).indexOf(contentItemId) > -1) {
        return this.signedInUserSubscriberContent[contentItemId]
      } else if (this.defaultSubscriberContent !== null && Object.keys(this.defaultSubscriberContent).indexOf(contentItemId) > -1) {
        return this.defaultSubscriberContent[contentItemId]
      } else {
        throw Error(`No subcriber content found for item ${contentItemId}.`)
      }
    }
  }
}

<template>
  <div :class="facilityPriceLevelClasses">
    <font-awesome-icon
      :icon="icon"
      :class="iconClasses"
    />
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'FacilityPriceLevel',
  props: {
    level: { type: Number, default: null, required: true },
    text: { type: String, default: null, required: true }
  },
  computed: {
    icon () {
      if (this.level === 5) {
        return 'star'
      } else if (this.level === 25) {
        return 'thumbs-up'
      } else if (this.level === 50) {
        return 'exclamation-circle'
      } else {
        return null
      }
    },
    facilityPriceLevelClasses () {
      return [
        'facility-price-level',
        `facility-price-level--${this.level}`
      ]
    },
    iconClasses () {
      return [
        'facility-price-level__icon',
        `facility-price-level__icon--${this.level}`
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.facility-price-level {
  font-family: $font-family-poppins;
  font-size: 0.9rem;
  font-weight: 600;

  &--5 {
    color: $primary;
  }

  &--25 {
    color: $brand-purple-1;
  }

  &--50 {
    color: #C74A4A;
  }
}
</style>

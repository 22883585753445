<template>
  <div class="row no-gutters align-items-center facility-listing-header">
    <div class="col-12 col-md-auto text-center text-md-left">
      <h3 class="p-0 m-0 mr-2 facility-listing-header__head">
        <oc-content :item="$oc.content.procedureDetailPageFacilityListingTitle" />
        in {{ pageData.location.city }}, {{ pageData.location.stateAbbreviation }} {{ pageData.location.zipCodeId }}
      </h3>
    </div>
    <div class="col-12 col-md-auto">
      <div class="font-weight-normal m-0 p-0 text-center text-md-left">
        ({{ sortedNearbyFacilities.length }} count)
      </div>
    </div>
  </div>
</template>

<script>
import { getterTypes } from '../../../store/modules/procedure-detail'

export default {
  name: 'FacilityListingHeader',
  computed: {
    pageData () {
      return this.$store.state.procedureDetail.pageData
    },
    sortedNearbyFacilities () {
      return this.$store.getters[getterTypes.PROCEDURE_DETAIL__GET_SORTED_NEARBY_FACILITIES]
    }
  }
}
</script>

<style lang="scss" scoped>
.facility-listing-header {
  &__head {
    font-size: 1.2rem;
    font-weight: 600;
  }
}
</style>

import { render, staticRenderFns } from "./FacilityContactCta.vue?vue&type=template&id=2850e15c&scoped=true&"
import script from "./FacilityContactCta.vue?vue&type=script&lang=js&"
export * from "./FacilityContactCta.vue?vue&type=script&lang=js&"
import style0 from "./FacilityContactCta.vue?vue&type=style&index=0&id=2850e15c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2850e15c",
  null
  
)

export default component.exports
<template>
  <div class="facility-map-info-window p-4">
    <div
      class="facility-map-info-window__close-button"
      @click="$emit('close')"
    >
      <font-awesome-icon icon="times" />
    </div>
    <div class="facility-map-info-window__facility-name mb-2">
      {{ name }}
    </div>
    <div class="facility-map-info-window__facility-address mb-2">
      <div>{{ facility.address1 }}</div>
      <div v-if="facility.address2">
        {{ facility.address2 }}
      </div>
      <div>{{ facility.city }}, {{ facility.stateAbbreviation }} {{ facility.zipCodeId }}</div>
    </div>
    <facility-price-level
      :level="priceLevel"
      :text="priceLevelName"
      class="mb-2"
    />
    <div
      v-if="priceFormatted"
      class="facility-map-info-window__price mb-1"
    >
      {{ priceFormatted }}
    </div>
    <facility-contact-cta
      v-if="procedureBundle === null"
      :facility="facility"
    />
    <div v-if="procedureBundle !== null">
      <schedule-appointment-cta
        class="mb-2"
        :show-subtext="procedureBundle !== null"
        @clicked="handleScheduleAppointmentCtaClick"
      />
      <schedule-appointment-modal
        ref="scheduleAppointmentModal"
        :procedure="procedure"
        :procedure-bundle="procedureBundle"
      />
    </div>
  </div>
</template>

<script>
import FacilityContactCta from '../FacilityListing/FacilityContactCta'
import FacilityPriceLevel from '../FacilityListing/FacilityPriceLevel'
import ScheduleAppointmentCta from '../ScheduleAppointmentCta'
import ScheduleAppointmentModal from '../../Shared/ScheduleAppointmentModal/ScheduleAppointmentModal'
import priceLevelNames from '../../../lib/priceLevelNames'

export default {
  name: 'FacilityMapInfoWindow',
  components: {
    FacilityContactCta,
    FacilityPriceLevel,
    ScheduleAppointmentCta,
    ScheduleAppointmentModal
  },
  props: {
    name: { type: String, default: null, required: true },
    facility: { type: Object, default: null, required: true },
    procedureBundle: { type: Object, default: null },
    price: { type: Number, default: null, required: false },
    priceLevel: { type: Number, default: null, required: false }
  },
  data () {
    return {
      showPhoneNumberModal: false,
      showScheduleAppointmentModal: false,
      priceLevelNames
    }
  },
  computed: {
    showPhone () {
      return this.facility.phone && this.facility.vettedDateUtc
    },
    priceFormatted () {
      if (this.price !== null) {
        const cost = parseInt(this.price, 10)
        return `$${new Intl.NumberFormat('en-US').format(cost)}`
      } else {
        return null
      }
    },
    procedure () {
      return this.$store.state.procedureDetail.pageData.procedure
    },
    priceLevelName () {
      return this.priceLevelNames[this.priceLevel]
    }
  },
  watch: {
    showScheduleAppointmentModal (v, ov) {
      this.$refs.scheduleAppointmentModal.setShowModal(v)
    }
  },
  methods: {
    handleScheduleAppointmentCtaClick () {
      this.$refs.scheduleAppointmentModal.setShowModal(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.facility-map-info-window {
    background-color: $white;
    border-radius: 10px;
    filter: drop-shadow(0 0.5rem 0.5rem $brand-medium-gray-1);
    position: relative;
    text-align: center;
    width: 280px;

    &__close-button {
      cursor: pointer;
      font-size: 1.4rem;
      position: absolute;
      right: 10px;
      top: 5px;
    }

    &__facility {
      &-name {
        font-size: 1.2rem;
        font-weight: 600;
      }

      &-address {
        color: $brand-medium-gray-2;
        font-size: 0.9rem;
      }
    }

    &__price {
      font-size: 2rem;
    }
}
</style>

<template>
  <location-listing-item
    :location-listing="locationListing"
    :order-number="(orderNumber + 1)"
  >
    <template v-slot:listing-item-details>
      <div class="row no-gutters">
        <div class="d-none d-md-block col-md-5">
          <facility-details
            v-if="locationListing.facility"
            :facility="locationListing.facility"
            :show-name="false"
          />
          <physician-details
            v-if="locationListing.physician && !locationListing.facility"
            :physician="locationListing.physician"
            :facility="locationListing.facility"
            :show-name="false"
          />
        </div>
        <div class="col-12 col-md-7">
          <div class="row no-gutters mb-3 mb-md-0">
            <div class="col-3 text-center">
              <img
                class="img-fluid"
                :src="bestBenefitBadgeImg"
              >
            </div>
            <div class="col-9 pl-1">
              <benefit-list :procedure-bundle="locationListing.procedureBundle" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:third-column>
      <div class="row no-gutters align-items-center">
        <div class="col-6 col-md-12 pr-2 pr-md-0 text-right text-md-center mb-2 mb-md-0">
          <facility-price-level
            v-if="locationListing.priceLevel"
            :level="locationListing.priceLevel"
            :text="priceLevelName"
          />
        </div>
        <div class="col-6 col-md-12 text-left text-md-center pl-2 pl-md-0  mb-2 mb-md-0">
          <procedure-bundle-price :procedure-bundle="locationListing.procedureBundle" />
        </div>
        <div class="col-12">
          <schedule-appointment-cta
            class="mb-2"
            :show-subtext="true"
            @clicked="handleScheduleAppointmentCtaClick"
          />
        </div>
      </div>
    </template>
    <template v-slot:bottom>
      <schedule-appointment-modal
        ref="scheduleAppointmentModal"
        :procedure="procedure"
        :procedure-bundle="locationListing.procedureBundle"
      />
    </template>
  </location-listing-item>
</template>

<script>
import BenefitList from './BenefitList'
import FacilityDetails from '../Shared/FacilityDetails'
import FacilityPriceLevel from '../FacilityListing/FacilityPriceLevel'
import LocationListingItem from '../LocationListingItem'
import PhysicianDetails from '../Shared/PhysicianDetails'
import ProcedureBundlePrice from '../ProcedureBundleListing/ProcedureBundlePrice'
import ScheduleAppointmentCta from '../ScheduleAppointmentCta'
import ScheduleAppointmentModal from '../../Shared/ScheduleAppointmentModal/ScheduleAppointmentModal'

import { CommonBands } from 'vue-media-queries'
import bestBenefitBadgeImg from '../../../assets/img/best-benefit-selection-badge.png'
import priceLevelNames from '../../../lib/priceLevelNames'

export default {
  name: 'ProcedureBundleItem',
  components: {
    BenefitList,
    FacilityDetails,
    FacilityPriceLevel,
    LocationListingItem,
    PhysicianDetails,
    ProcedureBundlePrice,
    ScheduleAppointmentCta,
    ScheduleAppointmentModal
  },
  mixins: [CommonBands.Bootstrap4.mixin],
  props: {
    locationListing: { type: Object, default: null, required: true },
    procedure: { type: Object, default: null, required: true },
    orderNumber: { type: Number, default: null, required: true }
  },
  data () {
    return {
      bestBenefitBadgeImg,
      priceLevelNames
    }
  },
  computed: {
    priceLevelName () {
      return this.priceLevelNames[this.locationListing.priceLevel]
    }
  },
  methods: {
    handleScheduleAppointmentCtaClick () {
      this.$refs.scheduleAppointmentModal.setShowModal(true)
    }
  }
}
</script>

<style scoped lang="scss">
</style>

<template>
  <div class="procedure-details pt-4 sticky-top">
    <div class="container container-xl">
      <div class="row mb-4">
        <div class="col-9">
          <search-procedure-location ref="searchProcedureLocation" />
        </div>
        <div class="col-3">
          <related-procedures-select />
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-4">
          <div class="procedure-details__column-inner-wrapper">
            <procedure-price-detail />
          </div>
        </div>
        <div class="col-4">
          <div class="procedure-details__column-inner-wrapper">
            <benefit-information />
          </div>
        </div>
        <div class="col-4">
          <div class="procedure-details__column-inner-wrapper">
            <decision-support />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import NoProcedurePriceData from './NoProcedurePriceData'
import BenefitInformation from './BenefitInformation'
import DecisionSupport from './DecisionSupport'
import ProcedurePriceDetail from './ProcedurePriceDetail'
import RelatedProceduresSelect from './RelatedProceduresSelect'
import SearchProcedureLocation from '../../Shared/Search/SearchProcedureLocation'

export default {
  name: 'ProcedureDetails',
  components: {
    BenefitInformation,
    DecisionSupport,
    ProcedurePriceDetail,
    RelatedProceduresSelect,
    SearchProcedureLocation
    // NoProcedurePriceData
  },
  data () {
    return {
      showPhoneNumberModal: false
    }
  },
  computed: {
    pageData () {
      return this.$store.state.procedureDetail.pageData
    },
    procedureDetailsPhoneNumberModalTitle () {
      return this.$oc.getSelectedLocalization(this.$oc.content.procedureDetailsPhoneNumberModalTitle.localization).content
    },
    procedureDetailsPhoneNumber () {
      return this.$store.state.app.benefitBuddyPhoneNumber
    }
  },
  methods: {
    handleCtaClick () {
      this.showPhoneNumberModal = true
    }
  }
}
</script>

<style lang="scss" scoped>
.procedure-details {
  background-color: $primary;
  top: $fixed-header-offset-top;
  z-index: $sticky-zindex + 10;

  &__column-inner-wrapper {
    background-color: #234baa;
    border-radius: 10px;
    margin: 0 5px;
  }
}
</style>

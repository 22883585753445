<template>
  <div class="row no-gutters align-items-center location-sorting-control">
    <div class="col-auto location-sorting-control__label-column">
      Within:
    </div>
    <b-dropdown
      id="dropdown-1"
      :text="selectedDistanceFilter.label"
      variant="default"
      class="location-sorting-control__dropdown"
      toggle-class="location-sorting-control__dropdown-toggle"
    >
      <b-dropdown-item
        v-for="(distanceFilter, distanceFilterIndex) in availableDistanceFilters"
        :key="distanceFilterIndex"
        @click="selectedDistanceFilter = distanceFilter"
      >
        {{ distanceFilter.label }}
      </b-dropdown-item>
    </b-dropdown>
    miles
  </div>
</template>

<script>
import locationListingSettings from '../../../../lib/locationListingSettings'
import { mutationTypes } from '../../../../store/modules/procedure-detail'

export default {
  name: 'DistanceFilters',
  data: function () {
    return {
      locationListingSettings
    }
  },
  computed: {
    selectedDistanceFilter: {
      get () {
        return this.$store.state.procedureDetail.locationListingSettings.distanceFilter
      },
      set (v) {
        this.$store.commit(mutationTypes.PROCEDURE_DETAIL__SET_LOCATION_LISTING_DISTANCE_FILTER, v)
      }
    },
    availableDistanceFilters () {
      return locationListingSettings.distanceFilters
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./styles.scss";
</style>

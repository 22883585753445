<template>
  <div>
    <div
      v-if="procedureBundle.bundleFeeType === 0"
      class="procedure-bundle-price"
    >
      {{ procedurePriceSingleFormatted }}
    </div>
    <div
      v-if="procedureBundle.bundleFeeType === 1"
      class="procedure-bundle-price"
    >
      {{ procedurePriceLowFormatted }} -
      {{ procedurePriceHighFormatted }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProcedureBundlePrice',
  props: {
    procedureBundle: { type: Object, defualt: null, required: true }
  },
  computed: {
    procedurePriceSingleFormatted () {
      const cost = parseInt(this.procedureBundle.feeSingle, 10)
      return `$${new Intl.NumberFormat('en-US').format(cost)}`
    },
    procedurePriceLowFormatted () {
      const cost = parseInt(this.procedureBundle.feeLow, 10)
      return `$${new Intl.NumberFormat('en-US').format(cost)}`
    },
    procedurePriceHighFormatted () {
      const cost = parseInt(this.procedureBundle.feeHigh, 10)
      return `$${new Intl.NumberFormat('en-US').format(cost)}`
    }
  }
}
</script>

<style lang="scss" scoped>
.procedure-bundle-price {
      font-family: $font-family-poppins;
      font-size: 1.5rem;
    }
</style>

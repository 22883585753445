<template>
  <div class="location-listing-controls-mobile container-fluid border-bottom">
    <div :class="buttonRowClasses">
      <div class="col-3 col-md-2 text-center">
        <button
          :class="getDisplayModeButtonClasses(mobileLocationListingDisplayModes.list)"
          @click="handleDisplayModeButtonClick(mobileLocationListingDisplayModes.list)"
        >
          <font-awesome-icon
            icon="bars"
            class="location-listing-controls-mobile__button-icon"
          /> List
        </button>
      </div>
      <div class="col-3 col-md-2 text-center">
        <button
          :class="getDisplayModeButtonClasses(mobileLocationListingDisplayModes.map)"
          @click="handleDisplayModeButtonClick(mobileLocationListingDisplayModes.map)"
        >
          <font-awesome-icon
            icon="map-marker"
            class="location-listing-controls-mobile__button-icon"
          /> Map
        </button>
      </div>
      <div class="col-3 offset-3 offset-md-5 text-right">
        <button
          class="btn location-listing-controls-mobile__button"
          @click="handleShowSortingFilterControlsButtonClick"
        >
          <font-awesome-icon
            icon="sliders-v"
            class="location-listing-controls-mobile__button-icon"
          />
        </button>
      </div>
    </div>
    <div v-if="showSortingFilterControls">
      <div class="row border-bottom">
        <div class="col py-2">
          <provider-type-filters />
        </div>
      </div>
      <div
        v-if="false"
        class="row border-bottom"
      >
        <div class="col py-2">
          <facility-type-filter />
        </div>
      </div>
      <div class="row">
        <div class="col py-2">
          <sort-options />
        </div>
        <div class="col py-2">
          <distance-filters />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DistanceFilters from './Controls/DistanceFilters'
import ProviderTypeFilters from './Controls/ProviderTypeFilters'
import SortOptions from './Controls/SortOptions'

import { mobileLocationListingDisplayModes, mutationTypes } from '../../../store/modules/procedure-detail'
import FacilityTypeFilter from './Controls/FacilityTypeFilter.vue'

export default {
  name: 'LocationListingControlsMobile',
  components: {
    FacilityTypeFilter,
    DistanceFilters,
    ProviderTypeFilters,
    SortOptions
  },
  data () {
    return {
      mobileLocationListingDisplayModes
    }
  },
  computed: {
    buttonRowClasses () {
      return {
        row: true,
        'border-bottom': this.showSortingFilterControls
      }
    },
    selectedMobileLocationListingDisplayMode: {
      get () {
        return this.$store.state.procedureDetail.locationListingSettings.mobileDisplayMode
      },
      set (v) {
        this.$store.commit(mutationTypes.PROCEDURE_DETAIL__SET_LOCATION_LISTING_MOBILE_DISPLAY_MODE, v)
      }
    },
    showSortingFilterControls: {
      get () {
        return this.$store.state.procedureDetail.locationListingSettings.showMobileFiltersSortingControls
      },
      set (v) {
        this.$store.commit(mutationTypes.PROCEDURE_DETAIL__SET_LOCATION_LISTING_MOBILE_SHOW_SORTING_FILTERING_CONTROLS, v)
      }
    }
  },
  methods: {
    getDisplayModeButtonClasses (buttonDisplayMode) {
      return {
        btn: true,
        'location-listing-controls-mobile__button': true,
        'location-listing-controls-mobile__button--selected': buttonDisplayMode === this.selectedMobileLocationListingDisplayMode
      }
    },
    handleDisplayModeButtonClick (buttonDisplayMode) {
      this.selectedMobileLocationListingDisplayMode = buttonDisplayMode
    },
    handleShowSortingFilterControlsButtonClick () {
      this.showSortingFilterControls = !this.showSortingFilterControls
    }
  }
}
</script>

<style lang="scss" scoped>
.location-listing-controls-mobile {
  &__button {
    border-radius: 0;
    font-size : 1.2rem;
    padding: 0;

    &--selected {
      border-bottom: 4px solid $primary;
    }

    &-icon {
      color: $primary;
    }
  }
}
</style>

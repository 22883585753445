<template>
  <div
    class="text-center mt-4 mb-4 pt-4 pb-4"
  >
    <div v-if="!pageData.procedure && !pageData.location">
      <oc-content :item="$oc.content.procedureDetailPageInvalidSearchMessageProcedureAndLocation" />
    </div>
    <div v-if="!pageData.procedure && pageData.location">
      <oc-content :item="$oc.content.procedureDetailPageInvalidSearchMessageProcedure" />
    </div>
    <div v-if="pageData.procedure && !pageData.location">
      <oc-content :item="$oc.content.procedureDetailPageInvalidSearchMessageLocation" />
    </div>
    <div class="mt-4 mb-4">
      <button
        type="button"
        class="btn btn-primary"
        @click="$emit('search-again-clicked')"
      >
        <oc-content :item="$oc.content.procedureDetailPageSearchAgainCtaLabel" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InvalidPageData',
  computed: {
    pageData () {
      return this.$store.state.procedureDetail.pageData
    }
  }
}
</script>

<style>
</style>

<template>
  <div
    v-if="hasVettedPhoneNumber"
    class="facility-contact-cta"
  >
    <div
      v-if="isPhoneSizedScreen"
      class="text-center"
    >
      <a
        class="btn btn-primary mb-2 facility-contact-cta__tel-link"
        :href="`tel://${facility.phone}`"
      >
        <font-awesome-icon icon="phone-alt" />&nbsp;
        <oc-content :item="$oc.content.sharedScheduleAppointmentCtaLabel" />
      </a>
      <div class="text-center">
        <oc-content :item="$oc.content.sharedFacilityContactCtaSubtext" />
      </div>
    </div>
    <schedule-appointment-cta
      v-if="!isPhoneSizedScreen"
      @clicked="showPhoneNumberModal = true"
    />
    <facility-phone-number-modal
      :facility="facility"
      :show="showPhoneNumberModal"
      @closed="showPhoneNumberModal = false"
    />
  </div>
</template>

<script>
import { CommonBands } from 'vue-media-queries'
import FacilityPhoneNumberModal from './FacilityPhoneNumberModal'
import ScheduleAppointmentCta from '../ScheduleAppointmentCta'

export default {
  name: 'FacilityContactCta',
  components: {
    FacilityPhoneNumberModal,
    ScheduleAppointmentCta
  },
  mixins: [CommonBands.Bootstrap4.mixin],
  props: {
    facility: { type: Object, default: null, required: true }
  },
  data () {
    return {
      showPhoneNumberModal: false
    }
  },
  computed: {
    isPhoneSizedScreen () {
      return this.$resize && this.isMdDown
    },
    hasVettedPhoneNumber () {
      return this.facility.phone && this.facility.vettedDateUtc
    }
  }
}
</script>

<style lang="scss" scoped>
.facility-contact-cta {
  &__tel-link {
    border-radius: 17px;
    font-size: 0.9rem;
    font-weight: 600;
    padding: 0.4rem 3rem;
    text-decoration: none;
  }
}
</style>

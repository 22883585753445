<template>
  <gmap-map
    id="location-map"
    ref="locationMap"
    class="location-map"
    :center="mapCenter"
    :zoom="mapZoom"
    :options="gMapOptions"
    map-type-id="terrain"
    :style="locationMapStyles"
  >
    <div v-if="googleMapsLoaded">
      <gmap-marker
        v-for="(location, locationIndex) in locations"
        :key="`pin-${locationIndex}`"
        :position="location.position"
        :icon="getIconOptions(location)"
        :label="{color: '#fff', fontSize: '12px', fontWeight: '600', text: location.orderNumber.toString()}"
        :clickable="true"
        @click="selectedLocation = location"
      />
      <gmap-custom-marker
        v-if="selectedLocation !== null"
        :marker="selectedLocation.position"
        :offset-x="10"
        :offset-y="-10"
      >
        <facility-map-info-window
          :name="selectedLocation.name"
          :facility="selectedLocation.facility"
          :price="selectedLocation.price"
          :price-level="selectedLocation.priceLevel"
          :procedure-bundle="selectedLocation.procedureBundle"
          @close="selectedLocation = null"
        />
      </gmap-custom-marker>
    </div>
  </gmap-map>
</template>

<script>
import { CommonBands } from 'vue-media-queries'
import { gmapApi } from 'gmap-vue'
import { mutationTypes } from '../../../store/modules/procedure-detail'
import * as VueGoogleMaps from 'gmap-vue'
import FacilityMapInfoWindow from './FacilityMapInfoWindow'
import GmapCustomMarker from 'vue2-gmap-custom-marker'
import Vue from 'vue'

import svgMapMarkerBlue from '../../../assets/svg/map-marker--blue.svg'
import svgMapMarkerPurple from '../../../assets/svg/map-marker--purple.svg'
import svgMapMarkerRed from '../../../assets/svg/map-marker--red.svg'
import svgMapMarkerGray from '../../../assets/svg/map-marker--gray.svg'

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLEMAPS_API_KEY,
    libraries: 'places',
    installComponents: true
  }
})

const zoomLevels = {
  default: 10,
  locationSelected: 12
}

export default {
  name: 'LocationMap',
  components: {
    FacilityMapInfoWindow,
    GmapCustomMarker
  },
  mixins: [CommonBands.Bootstrap4.mixin],
  props: {
    locations: { type: Array, default: function () { return [] }, required: true }
  },
  data () {
    return {
      mapZoom: zoomLevels.default,
      mapCenter: { lat: 37.70292834727898, lng: -97.32215572144213 }, // defaults Wichita, KS. mounted() fn to set bounds based on locations should make this not seen
      googleMapsLoaded: false,
      mapElHeight: null
    }
  },
  computed: {
    gMapOptions () {
      return {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false
      }
    },
    selectedLocation: {
      get () {
        return this.$store.state.procedureDetail.selectedMapLocation
      },
      set (v) {
        this.$store.commit(mutationTypes.PROCEDURE_DETAIL__SET_SELECTED_MAP_LOCATION, v)
      }
    },
    mapFullSize () {
      return this.$store.state.procedureDetail.mapFullSize
    },
    googleMapsApi: gmapApi,
    locationMapStyles () {
      if (this.mapElHeight !== null) {
        return { height: `${this.mapElHeight}px` }
      }

      return null
    },
    selectedUserLocation () {
      return this.$store.state.app.selectedLocation
    }
  },
  watch: {
    locations () {
      this.selectedLocation = null

      if (this.googleMapsLoaded) {
        this.setBounds()
      }
    },
    mapFullSize (v, ov) {
      if (v !== ov) {
        this.setBounds()
      }
    },
    selectedLocation (v) {
      if (v !== null) {
        if (this.isMdDown === true) {
          this.$scrollTo('#location-map', { offset: -200 })
        }

        this.centerAndZoomSelectionLocation()
      } else {
        this.mapZoom = zoomLevels.default
        this.setBounds()
      }
    },
    '$resize' () {
      this.setMapHeight()
    }
  },
  async mounted () {
    await this.$gmapApiPromiseLazy()

    this.googleMapsLoaded = true

    this.setMapHeight()

    this.setBounds()

    if (this.isSmDown === true) { // mobile only
      this.$scrollTo('#location-map', { offset: -200 })

      if (this.selectedLocation !== null) {
        setTimeout(() => { // wait a short time to ensure the map is displaying in browser
          this.centerAndZoomSelectionLocation()
        }, 200)
      }
    }
  },
  methods: {
    setBounds () {
      if (this.locations.length > 0) {
        var bounds = new this.googleMapsApi.maps.LatLngBounds()

        this.locations.forEach(location => {
          bounds.extend(location.position)
        })

        this.$refs.locationMap.$mapPromise.then((map) => {
          map.fitBounds(bounds)
        })
      } else {
        this.mapCenter = { lat: this.selectedUserLocation.latitude, lng: this.selectedUserLocation.longitude }
      }
    },
    getIconOptions (location) {
      return {
        labelOrigin: new this.googleMapsApi.maps.Point(15, 12),
        url: this.getSvgMarker(location),
        anchor: new this.googleMapsApi.maps.Point(0, 0),
        scaledSize: new this.googleMapsApi.maps.Size(30, 30),
        scale: 12
      }
    },
    getSvgMarker (location) {
      if (location.priceLevel === 5) {
        return svgMapMarkerBlue
      } else if (location.priceLevel === 25) {
        return svgMapMarkerPurple
      } else if (location.priceLevel === 50) {
        return svgMapMarkerRed
      } else {
        return svgMapMarkerGray
      }
    },
    centerAndZoomSelectionLocation () {
      let latOffset = 0

      if (this.isSmDown === true) {
        latOffset = 0.03
      }

      this.mapZoom = zoomLevels.locationSelected
      this.mapCenter = { lat: (this.selectedLocation.position.lat + latOffset), lng: this.selectedLocation.position.lng } // offset lat to show the complete info window
    },
    getOffset (el) { // gets an html elements offset from top/left of the document
      const rect = el.getBoundingClientRect()
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    },
    setMapHeight () {
      const mapElOffset = this.getOffset(document.querySelector('#location-map'))
      this.mapElHeight = document.documentElement.clientHeight - mapElOffset.top
    }
  }
}
</script>

<style lang="scss" scoped>
.location-map {
  height: 360px;
  width: 100%;

  @include media-breakpoint-up(md) {
    height: 600px;
  }

  @include media-breakpoint-up(md) {
    min-height: 600px;
  }
}
</style>

<style lang="scss">
.gmap-info-window {
  text-align: center;

  &__facility {
    &-name {
      font-weight: bold;
    }
  }
}
</style>

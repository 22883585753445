<template>
  <b-modal
    v-model="showModal"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    :hide-footer="true"
    :centered="true"
    size="lg"
    content-class="schedule-appointment-modal__content rounded-0"
    header-class="schedule-appointment-modal__header p-0"
    body-class="schedule-appointment-modal__body"
  >
    <div
      slot="modal-header"
      class="schedule-appointment-modal__header-wrapper"
    >
      <div class="modal-title schedule-appointment-modal__title">
        <h5 class="modal-title mb-3 schedule-appointment-modal__primary-title">
          <oc-content :item="$oc.content.scheduleAppointmentModalTitle" />
        </h5>
        <h6
          v-if="procedureBundle.physician !== null"
          class="mb-0 font-weight-normal schedule-appointment-modal__secondary-title"
        >
          with {{ procedureBundle.physician.name }}
        </h6>
      </div>
      <button
        type="button"
        aria-label="Close"
        class="close schedule-appointment-modal__header-close-btn"
        @click="showModal = false"
      >
        ×
      </button>
    </div>
    <p class="schedule-appointment-modal__lede">
      <oc-content :item="$oc.content.scheduleAppointmentModalLede" />
    </p>
    <div class="row">
      <div class="col col-md-8 offset-md-2">
        <schedule-appointment-form
          :procedure="procedure"
          :procedure-bundle="procedureBundle"
          @appointment-requested-submitted="handleAppointmentRequestSubmitted"
        />
      </div>
    </div>
    <div class="row">
      <div class="col col-md-10 offset-md-1">
        <p class="schedule-appointment-modal__disclaimer">
          <oc-content :item="$oc.content.scheduleAppointmentModalDisclaimer" />
        </p>
      </div>
    </div>
  </b-modal>
</template>

<script>
import ScheduleAppointmentForm from './ScheduleAppointmentForm'

export default {
  name: 'ScheduleAppointmentModal',
  components: {
    ScheduleAppointmentForm
  },
  props: {
    procedure: { type: Object, defualt: null, required: true },
    procedureBundle: { type: Object, defualt: null, required: true }
  },
  data () {
    return {
      showModal: false
    }
  },
  methods: {
    setShowModal (v) {
      this.showModal = v
    },
    handleAppointmentRequestSubmitted () {
      this.setShowModal(false)
    }
  }
}
</script>
<style lang="scss">
.schedule-appointment-modal {
  &__content {
    padding: 2rem;
  }
  &__header {
    border-bottom: 1px solid $dark-blue-2 !important;
    margin: 0;

    &-wrapper {
      width: 100%;
    }
  }
  &__title {
    display: inline-block;
  }
  &__body {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
<style lang="scss" scoped>
.schedule-appointment-modal {
  &__secondary-title {
    font-size: 1.5rem;
    font-weight: normal;
  }

  &__header-close-btn {
    font-size: 3rem;
    padding: 0.6rem 1rem;
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  &__lede {
    font-size: 1.1rem;
  }

  &__form-control {
    border-color: $primary;
    border-radius: 0;
    padding: 24px 12px;
  }

  &__disclaimer {
    font-size: 0.9rem;
  }

  @include media-breakpoint-up(md) {
    &__header-close-btn {
      top: 1.6rem;
    }
  }

  @include media-breakpoint-up(lg) {
    &__header-close-btn {
      top: 2rem;
    }
  }
}
</style>

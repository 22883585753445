<template>
  <div class="procedure-bundle-header row no-gutters align-items-center">
    <div class="col-12 col-md-auto">
      <h3 class="procedure-bundle-header__head p-0 m-0 mr-2">
        <oc-content :item="$oc.content.procedureDetailPageBundleListingTitle" />
      </h3>
    </div>
    <div class="d-none d-md-block col-12 col-md mb-2 mb-md-0">
      <div class="h5 font-weight-normal m-0 p-0">
        within {{ distanceFilterMiles }} miles of {{ location.city }}, {{ location.stateAbbreviation }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProcedureBundlesHeader',
  computed: {
    location () {
      return this.$store.state.procedureDetail.pageData.location
    },
    distanceFilterMiles () {
      return this.$store.state.procedureDetail.locationListingSettings.distanceFilter.label
    }
  }
}
</script>

<style lang="scss" scoped>
.procedure-bundle-header {

  &__head {
    font-size: 1.4rem;
    font-weight: 600;
    text-align: center;
  }

  @include media-breakpoint-up(md) {
    &__head {
      font-size: 1.75rem;
      text-align: left;
    }
  }
}
</style>

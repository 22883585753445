<template>
  <div>
    <div v-if="pageDataLoaded && pageDataIsValid">
      <div v-if="isDesktop">
        <procedure-details class="mb-4" />
        <div
          v-if="showProcedureBundles || showNearbyFacilities"
          class="container container-xl"
        >
          <div class="row">
            <div :class="listingColumnClassDesktop">
              <location-listing-display-controls />
            </div>
          </div>
          <div class="row no-gutters">
            <div :class="listingColumnClasses">
              <promotion-banner
                v-if="selectedPromotionBanner"
                :data="selectedPromotionBanner"
              />
              <procedure-bundle-listing
                v-if="showProcedureBundles"
                class="mb-4"
              />
              <facility-listing v-if="showNearbyFacilities" />
            </div>
            <div :class="mapColumnClasses">
              <div class="desktop-map-wrapper sticky-top">
                <location-map-size-toggle class="map-size-toggle" />
                <location-map :locations="mapLocations" />
                <administrator-info v-if="userIsAdministrator" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isDesktop">
        <search-procedure-location />
        <procedure-details-mobile class="mb-2" />
        <location-listing-display-controls-mobile v-if="showProcedureBundles || showNearbyFacilities" />
        <div
          v-if="mobileDisplayModeIsList"
          class="container-fluid"
        >
          <promotion-banner
            v-if="selectedPromotionBanner"
            :data="selectedPromotionBanner"
          />
          <procedure-bundle-listing
            v-if="showProcedureBundles"
            class="mb-4"
          />
          <facility-listing v-if="showNearbyFacilities" />
        </div>
        <div v-if="(showProcedureBundles || showNearbyFacilities) && mobileDisplayModeIsMap">
          <location-map :locations="mapLocations" />
        </div>
        <benefit-assistance-mobile v-if="showProcedureBundles" />
      </div>
      <div
        v-if="!showProcedureBundles && !showNearbyFacilities"
        class="container"
      >
        <div class="alert alert-warning mt-4">
          We were unable to find any facilities offering this procedure in this area.
        </div>
      </div>
    </div>
    <invalid-page-data
      v-if="pageDataLoaded && !pageDataIsValid"
      class="mt-4 mb-4"
    />
  </div>
</template>

<script>
import AdministratorInfo from '../../components/ProcedureDetailPage/AdministratorInfo/AdministratorInfo'
import BenefitAssistanceMobile from '../../components/ProcedureDetailPage/BenefitAssistanceMobile'
import FacilityListing from '../../components/ProcedureDetailPage/FacilityListing/FacilityListing'
import InvalidPageData from '../../components/ProcedureDetailPage/InvalidPageData'
import LocationListingDisplayControls from '../../components/ProcedureDetailPage/LocationListingDisplayControls/LocationListingDisplayControls'
import LocationListingDisplayControlsMobile from '../../components/ProcedureDetailPage/LocationListingDisplayControls/LocationListingDisplayControlsMobile'
import LocationMap from '../../components/ProcedureDetailPage/LocationMap/LocationMap'
import LocationMapSizeToggle from '../../components/ProcedureDetailPage/LocationMap/LocationMapSizeToggle'
import ProcedureBundleListing from '../../components/ProcedureDetailPage/ProcedureBundleListing/ProcedureBundleListing'
import ProcedureDetails from '../../components/ProcedureDetailPage/ProcedureDetails/ProcedureDetails'
import ProcedureDetailsMobile from '../../components/ProcedureDetailPage/ProcedureDetails/ProcedureDetailsMobile'
import PromotionBanner from '../../components/Shared/PromotionBanner/PromotionBanner'
import SearchProcedureLocation from '../../components/Shared/Search/SearchProcedureLocation'
import { CommonBands } from 'vue-media-queries'
import { getProcedurePricingDetails, getOrchardCoreContentModule, getOrchardCorePromotionBanners } from '../../lib/api'
import { mutationTypes as appMutationTypes } from '../../store/modules/app'
import { mutationTypes as procedureDetailMutationTypes, getterTypes as procedureDetailGetterTypes, mobileLocationListingDisplayModes } from '../../store/modules/procedure-detail'
import { find } from 'lodash'
import pageMixin from '../../lib/mixins/page'

export default {
  name: 'ProcedureDetail',
  components: {
    AdministratorInfo,
    BenefitAssistanceMobile,
    FacilityListing,
    InvalidPageData,
    LocationMap,
    LocationMapSizeToggle,
    LocationListingDisplayControls,
    LocationListingDisplayControlsMobile,
    ProcedureBundleListing,
    ProcedureDetails,
    ProcedureDetailsMobile,
    PromotionBanner,
    SearchProcedureLocation
  },
  mixins: [CommonBands.Bootstrap4.mixin, pageMixin],
  data () {
    return {
      listingColumnClassDesktop: 'col',
      promotionBanners: null
    }
  },
  computed: {
    pageData () {
      return this.$store.state.procedureDetail.pageData
    },
    pageDataIsValid () {
      return this.pageData != null && this.pageData.procedure !== null && this.pageData.location !== null
    },
    isDesktop () {
      return this.$resize && this.isLgUp
    },
    showProcedureBundles () {
      return this.pageData.procedureBundles && this.pageData.procedureBundles.length > 0
    },
    showNearbyFacilities () {
      return (this.pageData.nearbyFacilities && this.pageData.nearbyFacilities.length > 0) && this.showAllProviders
    },
    mapLocations () {
      return this.$store.getters[procedureDetailGetterTypes.PROCEDURE_DETAIL__GET_MAP_LOCATIONS]
    },
    mapFullSize () {
      return this.$store.state.procedureDetail.mapFullSize
    },
    listingColumnClasses () {
      return {
        [this.listingColumnClassDesktop]: !this.mapFullSize,
        'd-none': this.mapFullSize,
        'border-top': true,
        'location-listing-column': true
      }
    },
    mapColumnClasses () {
      return {
        'map-column': true,
        'col-5': !this.mapFullSize,
        'col-12': this.mapFullSize
      }
    },
    showAllProviders () {
      return this.$store.getters[procedureDetailGetterTypes.PROCEDURE_DETAIL__GET_SHOW_ALL_PROVIDERS]
    },
    mobileDisplayModeIsList () {
      return this.$store.getters[procedureDetailGetterTypes.PROCEDURE_DETAIL__GET_MOBILE_LOCATION_LISTING_MODE] === mobileLocationListingDisplayModes.list
    },
    mobileDisplayModeIsMap () {
      return this.$store.getters[procedureDetailGetterTypes.PROCEDURE_DETAIL__GET_MOBILE_LOCATION_LISTING_MODE] === mobileLocationListingDisplayModes.map
    },
    selectedPromotionBanner () {
      let promotionBannerId = null

      if (this.pageData.subscriberCategory !== null && this.pageData.subscriberCategory.promotionalBannerId !== null) {
        promotionBannerId = this.pageData.subscriberCategory.promotionalBannerId
      }

      if (promotionBannerId !== null) {
        return find(this.promotionBanners, ['alias.alias', promotionBannerId])
      }

      return null
    },
    signedInUser () {
      return this.$store.state.app.signedInUser
    },
    userIsAdministrator () {
      return true // one day will be dynamic via user roles
    }
  },
  watch: {
    async $route (v, ov) {
      await this.loadPageData()
    },
    signedInUser (v, ov) {
      if (v !== ov) {
        this.$router.go()
      }
    }
  },
  async mounted () {
    await this.loadPageData()
  },
  methods: {
    async loadPageData () {
      this.pageDataLoaded = null

      try {
        const signedInUserSubscriberId = (this.signedInUser) ? this.signedInUser.subscriber.subscriberId : null

        const response = await getProcedurePricingDetails(this.$route.params.procedureId, this.$route.params.zipCode, signedInUserSubscriberId)

        this.$store.commit(procedureDetailMutationTypes.PROCEDURE_DETAIL__SET_PAGE_DATA, response.data)
        this.$store.commit(appMutationTypes.APP__SET_SELECTED_PROCEDURE, response.data.procedure)
        this.$store.commit(appMutationTypes.APP__SET_SELECTED_LOCATION, response.data.location)

        const pageContentResponse = await getOrchardCoreContentModule('procedure-detail-page')
        this.$oc.setContent(pageContentResponse.data)

        const scheduleAppointmentModalContentResponse = await getOrchardCoreContentModule('schedule-appointment-modal')
        this.$oc.setContent(scheduleAppointmentModalContentResponse.data)

        const facilityPhoneNumberModalContentResponse = await getOrchardCoreContentModule('facility-phone-number-modal')
        this.$oc.setContent(facilityPhoneNumberModalContentResponse.data)

        const procedureDetailPagePhoneNumberModalContentResponse = await getOrchardCoreContentModule('procedure-details-phone-number-modal')
        this.$oc.setContent(procedureDetailPagePhoneNumberModalContentResponse.data)

        const promotionBannersResponse = await getOrchardCorePromotionBanners()
        this.promotionBanners = promotionBannersResponse.data

        this.pageDataLoaded = true
      } catch (error) {
        console.error(error)
        this.pageDataLoaded = false
      }
    }
  },
  metaInfo () {
    let title = 'Benefit Buddy - Procedure Detail'

    if (this.pageData !== null) {
      title = `Benefit Buddy - ${this.pageData.procedure.name} in ${this.pageData.procedurePricing.cbsa.name}`
    }

    return {
      title
    }
  }
}
</script>

<style lang="scss" scoped>
.map-column {
  position: relative;
}

.map-size-toggle {
  position: absolute;
  top: 10px;
  left: 0;
  z-index: $sticky-zindex + 1;
}

.location-listing-column {
  box-shadow: 10px 0px 5px -6px rgba(76,89,216,0.1);
  position: relative;
  z-index: 1000;
}

.desktop-map-wrapper {
  top: 270px;
}
</style>

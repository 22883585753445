<template>
  <div class="physician-details">
    <div
      v-if="showName"
      class="row"
    >
      <div class="col">
        <h4 class="physician-details__head mb-1">
          {{ physician.name }}
        </h4>
      </div>
    </div>
    <div class="row d-none d-md-block">
      <div class="col">
        <div>
          {{ address }}<br>
          {{ city }},
          {{ state }}
          {{ zip }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PhysicianDetails',
  props: {
    physician: { type: Object, default: null, required: true },
    facility: { type: Object, default: null, required: false },
    showName: { type: Boolean, default: true, required: false }
  },
  computed: {
    address () {
      return this.physician.hasaddress ? [this.physician.address1, ' ', this.physician.address2].join('') : [this.facility.address1, ' ', this.facility.address2].join('')
    },
    city () {
      return this.physician.hasaddress ? this.physician.city : this.facility.city
    },
    state () {
      return this.physician.hasaddress ? this.physician.stateAbbreviation : this.facility.stateAbbreviation
    },
    zip () {
      return this.physician.hasaddress ? this.physician.zipCodeId : this.facility.zipCodeId
    },
    distanceFromSelectedLocation () {
      return this.physician.hasaddress ? this.physician.distanceFromSelectedLocation : this.facility.distanceFromSelectedLocation
    }
  }
}
</script>

<style scoped lang="scss">
.physician-details {
  font-size: 0.8rem;

  &__head {
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }

  @include media-breakpoint-up(md) {
    &__head {
      font-size: 1rem;
    }
  }
}
</style>

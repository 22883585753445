<template>
  <div class="row no-gutters align-items-center location-sorting-control">
    <div class="col-auto location-sorting-control__label-column">
      Facility Type:
    </div>
    <b-dropdown
      v-if="facilityTypeFilterOptions"
      id="dropdown-1"
      :text="selectedFacilityTypeFilter.label"
      variant="default"
      class="location-sorting-control__dropdown"
      toggle-class="location-sorting-control__dropdown-toggle"
    >
      <b-dropdown-item
        v-for="(facilityTypeFilterOption) in facilityTypeFilterOptions"
        :key="facilityTypeFilterOption.key"
        @click="selectedFacilityTypeFilter = facilityTypeFilterOption"
      >
        {{ facilityTypeFilterOption.label }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import locationListingSettings from '../../../../lib/locationListingSettings'
import { mutationTypes } from '../../../../store/modules/procedure-detail'

export default {
  name: 'FacilityTypeFilter',
  data: function () {
    return {
      locationListingSettings
    }
  },
  computed: {
    selectedFacilityTypeFilter: {
      get () {
        return this.$store.state.procedureDetail.locationListingSettings.facilityTypeFilter
      },
      set (v) {
        this.$store.commit(mutationTypes.PROCEDURE_DETAIL__SET_LOCATION_LISTING_FACILITY_TYPE_FILTER, v)
      }
    },
    facilityTypeFilterOptions () {
      return this.$store.state.procedureDetail.facilityTypeFilterOptions
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./styles.scss";
</style>

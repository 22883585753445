<template>
  <div class="px-2">
    <procedure-bundles-header class="mt-4 mb-2" />
    <div v-if="sortedProcedureBundles.length > 0">
      <procedure-bundle
        v-for="(locationListing, locationListingIndex) in sortedProcedureBundles"
        :key="locationListingIndex"
        :procedure="procedure"
        :location-listing="locationListing"
        :order-number="locationListingIndex"
      />
    </div>
    <div
      v-else
      class="alert alert-info"
    >
      No preferred benefit providers were found matching this criteria.
    </div>
  </div>
</template>

<script>
import ProcedureBundle from './ProcedureBundleItem'
import ProcedureBundlesHeader from './ProcedureBundlesHeader'
import { getterTypes } from '../../../store/modules/procedure-detail'

export default {
  name: 'ProcedureBundleListing',
  components: {
    ProcedureBundle,
    ProcedureBundlesHeader
  },
  computed: {
    procedure () {
      return this.$store.state.procedureDetail.pageData.procedure
    },
    sortedProcedureBundles () {
      return this.$store.getters[getterTypes.PROCEDURE_DETAIL__GET_SORTED_PROCEDURE_BUNDLES]
    }
  }
}
</script>

<style>

</style>

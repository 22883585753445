<template>
  <div class="procedure-details py-1 sticky-top">
    <b-nav fill>
      <b-nav-item
        v-for="(navSection, navSectionKey) in navSections"
        :key="navSectionKey"
        class="procedure-details__nav-item"
        @click="handleNavitemClick(navSection)"
      >
        {{ navSection }}
        <font-awesome-icon
          v-if="navSection !== selectedNavSection"
          icon="chevron-down"
        />
        <font-awesome-icon
          v-if="navSection === selectedNavSection"
          icon="chevron-up"
        />
      </b-nav-item>
    </b-nav>
    <div class="container">
      <div class="row">
        <div class="col col-md-6 offset-md-3">
          <div v-if="selectedNavSection === navSections.fairPrice">
            <procedure-price-detail />
          </div>
          <div v-if="selectedNavSection === navSections.benefits">
            <benefit-information />
          </div>
          <div
            v-if="selectedNavSection === navSections.similar"
            class="p-2"
          >
            <related-procedures-select />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BenefitInformation from './BenefitInformation'
import RelatedProceduresSelect from './RelatedProceduresSelect'
import ProcedurePriceDetail from './ProcedurePriceDetail'

import { mobileLocationListingDisplayModes } from '../../../store/modules/procedure-detail'

/*
import NoProcedurePriceData from './NoProcedurePriceData'
import ProcedureDetailsMobileHeader from './ProcedureDetailsMobileHeader'
*/

const navSections = {
  fairPrice: 'Fair Price',
  benefits: 'Benefits',
  similar: 'Similar'
}

export default {
  name: 'ProcedureDetailsMobile',
  components: {
    BenefitInformation,
    RelatedProceduresSelect,
    ProcedurePriceDetail
    /*
    NoProcedurePriceData,
    ProcedureDetailsMobileHeader,
    ProcedurePriceDetail,
    */
  },
  data () {
    return {
      navSections,
      selectedNavSection: null
    }
  },
  computed: {
    pageData () {
      return this.$store.state.procedureDetail.pageData
    },
    procedurePrices () {
      return this.$store.state.procedurePrices
    },
    priceDetailTitle () {
      return `Fair price in ${this.pageData.location.city}, ${this.pageData.location.stateAbbreviation}`
    },
    procedureDetailsPhoneNumber () {
      return this.$store.state.app.benefitBuddyPhoneNumber
    },
    mobileLocationListingDisplayMode () {
      return this.$store.state.procedureDetail.locationListingSettings.mobileDisplayMode
    }
  },
  watch: {
    mobileLocationListingDisplayMode (v, ov) {
      if (v !== ov && v === mobileLocationListingDisplayModes.map) {
        this.selectedNavSection = null
      }
    }
  },
  mounted () {
    this.selectedNavSection = this.navSections.fairPrice
  },
  methods: {
    handleNavitemClick (navItem) {
      if (this.selectedNavSection === navItem) {
        this.selectedNavSection = null
      } else {
        this.selectedNavSection = navItem
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.procedure-details {
  background-color: #1949B0;
  top: $fixed-header-offset-top - 4; // not sure why -4 is needed on mobile but it looks right /shrug

  &__nav-item::v-deep .nav-link {
    color: $white;
    text-decoration: none;
  }
}
</style>

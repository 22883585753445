<template>
  <div class="location-listing-item py-3 mb-2">
    <div class="row no-gutters align-items-center">
      <div
        v-if="isMdUp"
        class="col-12 col-md-1"
      >
        <map-key
          v-if="showMapKey"
          :distance="distanceFromSelectedLocation"
          :order-number="orderNumber"
          :price-level="priceLevel"
          @clicked="handleMapKeyClicked"
        />
      </div>
      <div class="col-12 col-md-7">
        <h4
          v-if="isMdUp"
          class="location-listing-item__head mb-3 mb-lg-2"
        >
          {{ listingName }}
        </h4>
        <div
          v-if="isSmDown"
          class="row no-gutters align-items-center mb-3"
        >
          <div class="col-3">
            <map-key
              v-if="showMapKey"
              :distance="distanceFromSelectedLocation"
              :order-number="orderNumber"
              :price-level="priceLevel"
              @clicked="handleMapKeyClicked"
            />
          </div>
          <div class="col-9">
            <h4 class="location-listing-item__head mb-3 mb-lg-2">
              {{ listingName }}
            </h4>
          </div>
        </div>
        <slot name="listing-item-details" />
      </div>
      <div class="col-12 col-md-4 pr-lg-2">
        <slot name="third-column" />
      </div>
    </div>
    <div
      v-if="showAccreditations"
      class="row"
    >
      <div class="col-1" />
      <div class="col-11">
        <facility-accreditations
          :facility-accreditations="locationListing.facility.accreditations"
          class="location-listing-item__facility-accreditations"
        />
      </div>
    </div>
    <slot name="bottom" />
  </div>
</template>

<script>
import { actionTypes } from '../../store/modules/procedure-detail'
import { CommonBands } from 'vue-media-queries'

import FacilityAccreditations from './Shared/FacilityAccreditations'
import MapKey from './Shared/MapKey'

export default {
  name: 'LocationListingItem',
  components: {
    FacilityAccreditations,
    MapKey
  },
  mixins: [CommonBands.Bootstrap4.mixin],
  props: {
    locationListing: { type: Object, default: null, required: true },
    orderNumber: { type: Number, default: null, required: true }
  },
  computed: {
    distanceFromSelectedLocation () {
      if (this.locationListing.physician !== null && this.locationListing.physician.distanceFromSelectedLocation !== null) {
        return this.locationListing.physician.distanceFromSelectedLocation
      } else if (this.locationListing.facility !== null && this.locationListing.facility.distanceFromSelectedLocation !== null) {
        return this.locationListing.facility.distanceFromSelectedLocation
      }

      return null
    },
    listingName () {
      if (this.locationListing.facility !== null) {
        return this.locationListing.facility.name
      } else {
        return this.locationListing.physician.name
      }
    },
    showAccreditations () {
      return this.locationListing.facility.accreditations && this.locationListing.facility.accreditations.length > 0
    },
    showMapKey () {
      return this.distanceFromSelectedLocation !== null && this.locationListing.facility !== null && this.locationListing.facility.latitude !== null && this.locationListing.facility.longitude !== null
    },
    priceLevel () {
      if (this.locationListing.priceLevel !== undefined) {
        return this.locationListing.priceLevel
      } else if (this.locationListing.facility !== null) {
        return this.locationListing.facility.priceLevel
      }

      return null
    }
  },
  methods: {
    handleMapKeyClicked () {
      this.$store.dispatch(actionTypes.PROCEDURE_DETAIL__SET_SELECTED_MAP_LOCATION_BY_ORDER_NUMBER, this.orderNumber)
    }
  }
}
</script>

<style lang="scss" scoped>
.location-listing-item {
  border-bottom: 1px solid $brand-light-blue-1;

  &__head {
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }

  &__facility-accreditations {
    background-color: #f0f8ff;
    border-radius: 10px;
  }

  @include media-breakpoint-up(md) {
    &__head {
      font-size: 1.2rem;
    }
  }
}
</style>

<template>
  <div class="fixed-bottom benefit-assistance-mobile p-2">
    <div class="benefit-assistance-mobile__content">
      <div class="row align-items-center">
        <div class="col-7">
          This Procedures Qualifies for Benefit Assistance
        </div>
        <div class="col-5">
          <a
            :href="ctaHref"
            class="btn btn-primary btn-block benefit-assistance-mobile__button"
          >
            <font-awesome-icon icon="phone-alt" />
            Speak Now
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BenefitAssistanceMobile',
  computed: {
    benefitBuddyPhoneNumber () {
      return this.$store.state.app.benefitBuddyPhoneNumber
    },
    ctaHref () {
      return `tel://${this.benefitBuddyPhoneNumber}`
    }
  }
}
</script>

<style lang="scss" scoped>
.benefit-assistance-mobile {
  opacity: 0.9;
  background-color: $brand-purple-3;

  &__content {
    color: $white;
    font-size: 0.9rem;
    opacity: 1;
  }

  &__button {
    border-radius: 14px;
    text-decoration: none;
  }
}
</style>

<template>
  <div class="benefits-information">
    <div class="row">
      <div class="col-6">
        <h4 class="d-none d-lg-block">
          {{ getSubscriberContentItem('benefitsTitle') }}
        </h4>
        <vue-markdown :source="getSubscriberContentItem('benefitsCopyPrimary').markdown" />
      </div>
      <div class="col-6">
        <vue-markdown :source="getSubscriberContentItem('benefitsCopySecondary').markdown" />
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import subscriberContentMixin from '../../../lib/mixins/subscriberContent'

export default {
  name: 'BenefitInformation',
  components: {
    VueMarkdown
  },
  mixins: [subscriberContentMixin]
}
</script>

<style lang="scss" scoped>
.benefits-information {
  color: $white;
  padding: 8px;

  h4 {
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: 6px;
  }

  &::v-deep p {
    font-size: 0.7rem;
    line-height: 1.2rem;
    margin: 0 0 6px 0;
    padding: 0;
  }

  &::v-deep ul {
    font-size: 0.8rem;
    line-height: 20px;
    margin: 0.4rem 0 0 0;
    padding: 0 0 0 1rem;
  }
}
</style>

<template>
  <div
    v-if="relatedProcedures.length > 0"
    class="related-procedure-select"
  >
    <select
      v-model="selectedProcedure"
      class="form-control related-procedure-select__form-control"
    >
      <option value="">
        See similar procedures
      </option>
      <option
        v-for="option in relatedProcedures"
        :key="option.procedureId"
        :value="option.procedureId"
      >
        {{ option.procedureName }}
      </option>
    </select>
  </div>
</template>

<script>
import { PROCEDURE_DETAIL_PAGE } from '../../../router/routes.types'

export default {
  name: 'RelatedProceduresSelect',
  data () {
    return {
      selectedProcedure: ''
    }
  },
  computed: {
    relatedProcedures () {
      return this.$store.state.procedureDetail.pageData.relatedProcedures
    }
  },
  watch: {
    selectedProcedure (v, ov) {
      this.$router.push({ name: PROCEDURE_DETAIL_PAGE, params: { procedureId: v, zipCode: this.$route.params.zipCode } })
    }
  }
}
</script>

<style lang="scss" scoped>
.related-procedure-select {
  &__form-control {
    background-color: $white;
    border-radius: 9px;
    color: $dark-blue-2;
    height: 54px;
  }
}
</style>

<template>
  <div
    class="distance-from-selected-location"
    @click="$emit('clicked')"
  >
    <div class="distance-from-selected-location__map-marker mb-1">
      <img
        :src="svgMapMarker"
        class="distance-from-selected-location__map-marker-image"
      >
      <div
        v-if="orderNumber"
        class="distance-from-selected-location__map-marker-text"
      >
        {{ orderNumber }}
      </div>
    </div>
    <div class="distance-from-selected-location__distance">
      {{ distanceFormatted }} mi
    </div>
  </div>
</template>

<script>
import svgMapMarkerBlue from '../../../assets/svg/map-marker--blue.svg'
import svgMapMarkerPurple from '../../../assets/svg/map-marker--purple.svg'
import svgMapMarkerRed from '../../../assets/svg/map-marker--red.svg'
import svgMapMarkerGray from '../../../assets/svg/map-marker--gray.svg'

export default {
  name: 'MapKey',
  props: {
    distance: { type: Number, required: true, default: 0 },
    orderNumber: { type: Number, default: null, required: false },
    priceLevel: { type: Number, default: null, required: true }
  },
  computed: {
    svgMapMarker () {
      if (this.priceLevel === 5) {
        return svgMapMarkerBlue
      } else if (this.priceLevel === 25) {
        return svgMapMarkerPurple
      } else if (this.priceLevel === 50) {
        return svgMapMarkerRed
      } else {
        return svgMapMarkerGray
      }
    },
    distanceFormatted () {
      if (this.distance >= 1) {
        return this.distance.toFixed(0)
      } else {
        return '< 1'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.distance-from-selected-location {
  cursor: pointer;
  font-size: 12px;
  text-align: center;

  &__map-marker {
    position: relative;
    text-align: center;

    &-image {
      width: 20px;
      height: auto;
    }

    &-text {
      color: $white;
      font-weight: 600;
      position: absolute;
      top: 1px;
      left: -1px;
      width: 100%;
      text-align: center;
    }
  }

  &__distance {
    border-bottom: 1px dotted $black;
    display: inline-block;
  }
}
</style>

<template>
  <div class="decision-support">
    <div
      v-if="decisionSupportAvailable"
      class="row"
    >
      <div class="col-8">
        <h4>
          {{ getSubscriberContentItem('decisionSupportAvailableTitle') }}
        </h4>
        <vue-markdown :source="getSubscriberContentItem('decisionSupportAvailableCopy').markdown" />
      </div>
      <div class="col-4 text-center py-1">
        <div v-if="decsionSupportPhoneNumber !== null">
          <font-awesome-icon
            icon="headset"
            class="decision-support__icon mx-auto mb-1"
          />
          <button
            type="button"
            class="decision-support__cta btn-block"
            @click="handleCtaClick"
          >
            Speak To Us
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="!decisionSupportAvailable"
      class="row"
    >
      <div class="col-8">
        <h4>
          Decision Support
        </h4>
        <p>
          Compare providers below and try and schedule with a Best Benefit Selection Provider who offers Quality Care at a Fair Price.
        </p>
      </div>
      <div class="col-4 text-center py-1">
        <img
          class="decision-support__best-benefit-image"
          :src="imgBestBenefitSelectionBadgeWhiteCircle"
        >
      </div>
    </div>
    <contact-benefit-buddy-modal
      v-if="decsionSupportPhoneNumber !== null"
      ref="contactBenefitBuddyModal"
      :phone-number="decsionSupportPhoneNumber"
    />
  </div>
</template>

<script>
import ContactBenefitBuddyModal from './ContactBenefitBuddyModal'
import VueMarkdown from 'vue-markdown'

import imgBestBenefitSelectionBadgeWhiteCircle from '../../../assets/img/best-benefit-selection-badge--white-circle.png'

import subscriberContentMixin from '../../../lib/mixins/subscriberContent'

export default {
  name: 'DecisionSupport',
  components: {
    ContactBenefitBuddyModal,
    VueMarkdown
  },
  mixins: [subscriberContentMixin],
  data () {
    return {
      imgBestBenefitSelectionBadgeWhiteCircle
    }
  },
  computed: {
    pageData () {
      return this.$store.state.procedureDetail.pageData
    },
    decisionSupportAvailable () {
      if (this.subscriberCategory !== null) {
        return this.subscriberCategory.decisionSupportAvailable
      }

      return false
    },
    subscriberCategory () {
      return this.pageData.subscriberCategory
    },
    decsionSupportPhoneNumber () {
      if (this.decisionSupportAvailable && this.subscriberCategory.decisionSupportPhone !== null) {
        return this.subscriberCategory.decisionSupportPhone
      }

      return null
    }
  },
  methods: {
    handleCtaClick () {
      this.$refs.contactBenefitBuddyModal.showModal()
    }
  }
}
</script>

<style lang="scss" scoped>
.decision-support {
  color: $white;
  padding: 8px;
  background-image: url('../../../assets/svg/decision-support-background.svg');
  background-repeat: no-repeat;
  background-position: 66% 0;

  h4 {
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: 6px;
  }

  &::v-deep p {
    font-size: 0.7rem;
    line-height: 0.9rem;
    margin: 0 0 5px 0;
    padding: 0;
  }

  &__icon {
    color: $primary;
    font-size: 1.6rem;
  }

  &__cta {
    background-color: transparent;
    border-radius: 14px;
    border: 1px solid $white;
    color: $white;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 4px 8px;
  }

  &__best-benefit-image {
    width: 64px;
    height: 61px;
  }
}
</style>

<template>
  <b-modal
    v-model="showModal"
    :hide-footer="true"
    :centered="true"
    :title="facilityPhoneNumberModalTitle"
    content-class="rounded-0"
  >
    <slot name="body">
      <oc-content :item="$oc.content.facilityPhoneNumberModalBody" />
      <h3 class="text-center">
        {{ facility.phone }}
      </h3>
    </slot>
  </b-modal>
</template>

<script>
export default {
  name: 'FacilityPhoneNumberModal',
  props: {
    show: { type: Boolean, default: false, required: true },
    facility: { type: Object, default: null, required: true }
  },
  computed: {
    showModal: {
      get () {
        return this.show
      },
      set (v) {
        if (v === false) this.$emit('closed')
      }
    },
    facilityPhoneNumberModalTitle () {
      return this.$oc.getSelectedLocalization(this.$oc.content.facilityPhoneNumberModalTitle.localization).content
    }
  }
}
</script>

<style>

</style>

<template>
  <div>
    <facility-listing-header
      id="facility-listing-header"
      class="mt-4 mb-4"
    />
    <div v-if="sortedNearbyFacilities && sortedNearbyFacilities.length > 0">
      <paginate
        ref="facilityListingPaginator"
        name="facilitiesList"
        :list="sortedNearbyFacilities"
        :per="numFacilitiesPerPage"
        tag="div"
      >
        <facility-listing-item
          v-for="(locationListingItem, facilityIndex) in paginated('facilitiesList')"
          :key="locationListingItem.facility.facilityNpi"
          :location-listing="locationListingItem"
          :order-number="getFacilityOrderNumber(facilityIndex)"
        />
      </paginate>
      <paginate-links
        v-if="sortedNearbyFacilities.length > numFacilitiesPerPage"
        for="facilitiesList"
        :limit="paginateLinksLimit"
        :show-step-links="true"
        :classes="paginateLinksClassesConfig"
        :step-links="paginateStepLinksConfig"
        @change="handleFacilityPaginateChange"
      />
    </div>
    <div
      v-else
      class="alert alert-info"
    >
      No other medical providers were found matching this criteria.
    </div>
  </div>
</template>

<script>
import FacilityListingHeader from './FacilityListingHeader'
import FacilityListingItem from './FacilityListingItem'
import { CommonBands } from 'vue-media-queries'
import { getterTypes, mutationTypes } from '../../../store/modules/procedure-detail'

export default {
  name: 'FacilityListing',
  components: {
    FacilityListingHeader,
    FacilityListingItem
  },
  mixins: [CommonBands.Bootstrap4.mixin],
  data () {
    return {
      paginate: ['facilitiesList']
    }
  },
  computed: {
    pageData () {
      return this.$store.state.procedureDetail.pageData
    },
    sortedNearbyFacilities () {
      return this.$store.getters[getterTypes.PROCEDURE_DETAIL__GET_SORTED_NEARBY_FACILITIES]
    },
    sortedProcedureBundles () {
      return this.$store.getters[getterTypes.PROCEDURE_DETAIL__GET_SORTED_PROCEDURE_BUNDLES]
    },
    numFacilitiesPerPage () {
      if (this.$resize && this.isMdUp) {
        return 8
      } else {
        return 4
      }
    },
    paginateLinksLimit () {
      if (this.$resize && this.isMdUp) {
        return 5
      } else {
        return 3
      }
    },
    paginateLinksClassesConfig () {
      return {
        ul: ['nav', 'justify-content-left'],
        li: 'nav-item',
        a: 'nav-link'
      }
    },
    paginateStepLinksConfig () {
      if (this.$resize && this.isMdUp) {
        return {
          next: 'Next',
          prev: 'Previous'
        }
      } else {
        return {
          next: '»',
          prev: '«'
        }
      }
    },
    facilityListingSortOrder () {
      return this.$store.state.procedureDetail.facilityListingSortOrder
    },
    facilityOrderNumberStart () {
      try {
        return ((this.paginate.facilitiesList.page * this.numFacilitiesPerPage) + this.sortedProcedureBundles.length) + 1
      } catch {
        return null
      }
    }
  },
  watch: {
    facilityListingSortOrder () {
      this.$refs.facilityListingPaginator.goToPage(1)
    },
    'paginate.facilitiesList.list' (v) {
      this.$store.commit(mutationTypes.PROCEDURE_DETAIL__SET_VISIBLE_FACILITY_LISTINGS, v)
    },
    facilityOrderNumberStart (v, ov) {
      if (v !== ov) {
        this.$store.commit(mutationTypes.PROCEDURE_DETAIL__SET_FACILITY_ORDER_NUMBER_START, v)
      }
    }
  },
  methods: {
    getFacilityOrderNumber (facilityIndex) {
      return this.facilityOrderNumberStart + facilityIndex
    },
    handleFacilityPaginateChange () {
      this.$scrollTo('#facility-listing-header', 200, { offset: -130 }) // offset is needed to account for the fixed header
    }
  }
}
</script>

<style lang="scss">
.paginate-links {
  user-select: none;

  & .nav-link {
    cursor: pointer;
    padding: 0.5rem 0.75rem;
    &:hover {
      text-decoration: underline;
    }
  }

  & .nav-item {
    &.active > .nav-link {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  @include media-breakpoint-up(md) {
    & .nav-link {
      padding: 0.5rem 1rem;
    }
  }
}
</style>

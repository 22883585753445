<template>
  <div class="row no-gutters align-items-center location-sorting-control">
    <div class="col-auto location-sorting-control__label-column">
      Sort:
    </div>
    <div class="col">
      <b-dropdown
        id="dropdown-1"
        :text="selectedSorter.label"
        variant="default"
        class="location-sorting-control__dropdown"
        toggle-class="location-sorting-control__dropdown-toggle"
      >
        <b-dropdown-item
          v-for="(sorter, sorterIndex) in locationListingSettings.sorters"
          :key="sorterIndex"
          @click="selectedSorter = sorter"
        >
          {{ sorter.label }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import locationListingSettings from '../../../../lib/locationListingSettings'
import { mutationTypes } from '../../../../store/modules/procedure-detail'

export default {
  name: 'SortOptions',
  data: function () {
    return {
      locationListingSettings
    }
  },
  computed: {
    selectedSorter: {
      get () {
        return this.$store.state.procedureDetail.locationListingSettings.sort
      },
      set (v) {
        this.$store.commit(mutationTypes.PROCEDURE_DETAIL__SET_LOCATION_LISTING_SORT, v)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./styles.scss";
</style>

<template>
  <div class="promotion-banner mt-3 p-3 mr-lg-4">
    <div :class="detailsRowClasses">
      <div class="d-none d-md-block col-md-2 text-center">
        <img
          :src="$oc.buildMediaUrl(data.image.urls[0])"
          class="promotion-banner__image"
        >
      </div>
      <div class="col-12 col-md-10 px-md-2 text-center text-md-left mb-1 mb-md-0">
        <h3 class="promotion-banner__primary-heading">
          {{ promotionBannerLocalization.primaryHeading }}
        </h3>
        <h4
          v-if="promotionBannerLocalization.secondaryHeading !== null"
          class="promotion-banner__secondary-heading"
        >
          {{ promotionBannerLocalization.secondaryHeading }}
        </h4>
        <div class="promotion-banner__body d-none d-md-block mb-2">
          <vue-markdown :source="promotionBannerLocalization.body.markdown" />
        </div>
        <div class="row justify-content-end">
          <div class="col-12 col-md-auto text-center">
            <div
              v-if="data.overCTAText !== null"
              class="promotion-banner__over-cta-text mb-2"
            >
              {{ data.overCTAText }}
            </div>
            <div>
              <a
                class="btn btn-primary promotion-banner__cta"
                :href="promotionBannerLocalization.ctaUrl"
                target="_blank"
              >
                {{ promotionBannerLocalization.ctaLabel }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'

export default {
  name: 'PromotionBanner',
  components: {
    VueMarkdown
  },
  props: {
    data: { type: Object, default: null, required: true }
  },
  computed: {
    promotionBannerLocalization () {
      return this.$oc.getSelectedLocalization(this.data.localization)
    },
    detailsRowClasses () {
      return {
        row: true,
        'no-gutters': true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.promotion-banner {
  background-color: $white;
  border: 1px solid #234baa;
  font-family: $font-family-poppins;

  &__primary-heading {
    color: #3c6acc;
    font-size: 1.2rem;
    font-weight: 600;
  }

  &__secondary-heading {
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: 0.4rem;
  }

  &__image {
    width: 100%;
    padding: 0 1rem;
  }

  &__body {
    font-size: 0.9rem;

    &::v-deep p:last-child {
      margin-bottom: 0;
    }
  }

  &__over-cta-text {
    font-family: $font-family-poppins;
    font-size: 1.5rem;
    font-weight: 600;
  }

  &__cta {
    background-color: #196ecf;
    border: none;
    border-radius: 0;
    color: $white;
    font-size: 0.9rem;
    font-weight: 600;
    text-decoration: none;
  }

  @include media-breakpoint-up(lg) {
    &__primary-heading {
      font-size: 1.4rem;
    }

    &__secondary-heading {
      font-weight: 600;
    }

    &__body {
      font-size: 0.9rem;
    }
  }
}
</style>

<template>
  <button
    type="button"
    class="btn location-map-size-toggle"
    @click="handleToggleClick"
  >
    <font-awesome-icon
      v-if="!mapFullSize"
      icon="chevron-left"
    />
    <font-awesome-icon
      v-if="mapFullSize"
      icon="chevron-right"
    />
  </button>
</template>

<script>
import { mutationTypes } from '../../../store/modules/procedure-detail'
export default {
  name: 'LocationMapSizeToggle',
  computed: {
    mapFullSize () {
      return this.$store.state.procedureDetail.mapFullSize
    }
  },
  methods: {
    handleToggleClick () {
      this.$store.commit(mutationTypes.PROCEDURE_DETAIL__TOGGLE_MAP_FULL_SIZE)
    }
  }
}
</script>

<style lang="scss" scoped>
.location-map-size-toggle {
  background-color: $white;
  border: 1px solid #979797;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>

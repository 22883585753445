<template>
  <ul class="benefit-list">
    <li v-if="!procedureBundle.hasBalanceBillingRisk">
      <a
        v-b-popover.hover.top="guaranteedFairPricingPopoverConfig"
        href="#"
        class="benefit-list__link"
        @click.prevent=""
      >
        {{ guaranteedFairPricingPopoverConfig.title }}
      </a>
    </li>
    <li v-if="procedureBundle.hasBenefitsAssistance">
      <a
        v-b-popover.hover.top="zeroBalanceBillRiskPopoverConfig"
        href="#"
        class="benefit-list__link"
        @click.prevent=""
      >
        {{ zeroBalanceBillRiskPopoverConfig.title }}
      </a>
    </li>
    <li>
      <a
        v-b-popover.hover.top="specialFinancingPopoverConfig"
        href="#"
        class="benefit-list__link"
        @click.prevent=""
      >
        {{ specialFinancingPopoverConfig.title }}
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'BenefitList',
  props: {
    procedureBundle: { type: Object, default: null, required: true }
  },
  computed: {
    guaranteedFairPricingPopoverConfig () {
      return {
        title: 'Guaranteed Fair Pricing',
        content: 'Guarantees that you are getting a Fair Price and offers you the chance to get a refund for the difference if you find a cheaper price in your market.'
      }
    },
    zeroBalanceBillRiskPopoverConfig () {
      return {
        title: 'Zero Balance Bill Risk',
        content: 'Guarantees that this provider will not bill you for anything above the published price.'
      }
    },
    specialFinancingPopoverConfig () {
      return {
        title: 'Special Financing',
        content: 'Benefit Buddy offers short-term financing options (no interest) for this procedure.'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.benefit-list {
  color: $brand-dark-gray-1;
  line-height: 1.4rem;
  margin: 0 0 0 1.3rem;
  padding: 0;

  &__link {
    color: $dark-blue-1;
    font-size: 0.9rem;
    text-decoration: none;
  }

  @include media-breakpoint-up(md) {
    &__link {
      font-size: 0.8rem;
    }
  }

  @include media-breakpoint-up(lg) {
    &__link {
      font-size: 0.9rem;
    }
  }
}
</style>

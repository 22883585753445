import { render, staticRenderFns } from "./ProcedureBundleItem.vue?vue&type=template&id=6a6301e3&scoped=true&"
import script from "./ProcedureBundleItem.vue?vue&type=script&lang=js&"
export * from "./ProcedureBundleItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a6301e3",
  null
  
)

export default component.exports
<template>
  <div class="facility-accreditations mt-2 px-3 py-2">
    <strong><oc-content :item="$oc.content.procedureDetailPageFacilityAccreditationsTitle" />:</strong>
    {{ accreditationNamesJoined }}
  </div>
</template>

<script>
export default {
  name: 'FacilityAccreditations',
  props: {
    facilityAccreditations: { type: Array, default: null, required: true }
  },
  computed: {
    accreditationNamesJoined () {
      const result = []
      this.facilityAccreditations.forEach(fAccr => {
        result.push(fAccr.accreditation.name)
      })

      return result.join(', ')
    }
  }
}
</script>

<style lang="scss" scoped>
.facility-accreditations {
  font-size: 0.9rem;
}
</style>

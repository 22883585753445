<template>
  <button
    class="btn btn-primary schedule-appointment-cta mx-auto d-block"
    :class="btnClasses"
    type="button"
    @click.stop="$emit('clicked')"
  >
    <div><oc-content :item="$oc.content.sharedScheduleAppointmentCtaLabel" /></div>
    <div
      v-if="showSubtext"
      class="schedule-appointment-cta__sub-text"
    >
      <oc-content :item="$oc.content.sharedScheduleAppointmentCtaSubtext" />
    </div>
  </button>
</template>

<script>
export default {
  name: 'ScheduleAppointmentCta',
  props: {
    addBtnClasses: { type: Array, default: function () { return [] }, required: false },
    showSubtext: { type: Boolean, default: false, required: false }
  },
  computed: {
    btnClasses () {
      const result = this.addBtnClasses
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
.schedule-appointment-cta {
  color: $brand-dark-gray-1;
  border-radius: 17px;
  font-family: $font-family-poppins;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.2rem 3rem;

  &__sub-text {
    font-size: 0.8rem;
    font-weight: normal;
    font-style: italic;
  }

  &:hover {
    color: $white;
  }

  @include media-breakpoint-up(md) {
    padding: 0.2rem 1.8rem;
  }
}
</style>

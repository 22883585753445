<template>
  <div class="row no-gutters location-sorting-control">
    <div class="col-auto location-sorting-control__label-column">
      Show:
    </div>
    <div class="col-auto">
      <b-dropdown
        id="dropdown-1"
        :text="selectedProviderTypeFilter.label"
        variant="default"
        class="location-sorting-control__dropdown"
        toggle-class="location-sorting-control__dropdown-toggle"
      >
        <b-dropdown-item
          v-for="(providerTypeFilter, providerTypeFilterIndex) in locationListingSettings.providerTypeFilters"
          :key="providerTypeFilterIndex"
          @click="selectedProviderTypeFilter = providerTypeFilter"
        >
          {{ providerTypeFilter.label }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import locationListingSettings from '../../../../lib/locationListingSettings'
import { mutationTypes } from '../../../../store/modules/procedure-detail'

export default {
  name: 'ProviderTypeFilters',
  data: function () {
    return {
      locationListingSettings
    }
  },
  computed: {
    selectedProviderTypeFilter: {
      get () {
        return this.$store.state.procedureDetail.locationListingSettings.providerTypeFilter
      },
      set (v) {
        this.$store.commit(mutationTypes.PROCEDURE_DETAIL__SET_LOCATION_LISTING_PROVIDER_TYPE_FILTER, v)
      }
    }
  },
  methods: {
    providerTypeFilterButtonClasses (providerTypeFilter) {
      return {
        btn: true,
        'btn-sm': true,
        'location-sorting-control__btn': true,
        'location-sorting-control__btn--selected': providerTypeFilter.key === this.selectedProviderTypeFilter.key
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./styles.scss";
</style>

<template>
  <location-listing-item
    :procedure-bundle="null"
    :location-listing="locationListing"
    :order-number="orderNumber"
  >
    <template v-slot:listing-item-details>
      <div
        class="row"
      >
        <div class="col">
          <facility-details
            v-if="locationListing.facility"
            :facility="locationListing.facility"
            :show-name="false"
          />
        </div>
      </div>
    </template>
    <template v-slot:third-column>
      <div class="row no-gutters align-items-center">
        <div class="col-12 pr-2 pr-md-0 text-center">
          <facility-price-level
            :level="locationListing.facility.priceLevel"
            :text="locationListing.facility.priceLevelName"
            class="mb-2"
          />
        </div>
        <div class="col-12">
          <facility-contact-cta
            :facility="locationListing.facility"
            class="mb-2"
          />
        </div>
      </div>
    </template>
  </location-listing-item>
</template>

<script>
import FacilityContactCta from './FacilityContactCta'
import FacilityDetails from '../Shared/FacilityDetails'
import FacilityPriceLevel from './FacilityPriceLevel'
import LocationListingItem from '../LocationListingItem'

import { CommonBands } from 'vue-media-queries'

export default {
  name: 'FacilityListingItem',
  components: {
    FacilityContactCta,
    FacilityDetails,
    FacilityPriceLevel,
    LocationListingItem
  },
  mixins: [CommonBands.Bootstrap4.mixin],
  props: {
    locationListing: { type: Object, default: null, required: true },
    orderNumber: { type: Number, default: 0, required: true }
  }
}
</script>

<style scoped lang="scss">
</style>
